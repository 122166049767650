import { RecorderMode } from "../mode/types";
import AgainIcon from "./../../../../common/assets/vectors/again.svg";
import PauseIcon from "./../../../../common/assets/vectors/pause.svg";
import RecordIcon from "./../../../../common/assets/vectors/record.svg";
import ResumeIcon from "./../../../../common/assets/vectors/resume.svg";
import StopIcon from "./../../../../common/assets/vectors/stop.svg";

interface RecorderButtonsProps {
  audioEnabled: boolean;
  mode: RecorderMode;
  showDock2Record: boolean;
}

const RecorderButtons = (props: RecorderButtonsProps) => {
  const { audioEnabled, mode, showDock2Record } = props;

  let dock2RecordAction;

  if (mode === RecorderMode.REPLY) {
    dock2RecordAction = "then you can reply!";
  } else {
    dock2RecordAction = "then you can send!";
  }

  return (
    <section className="buttons">
      <button className="record" disabled>
        <span className="svg">
          <RecordIcon />
        </span>
        <span>Record video</span>
      </button>
      <span
        className="hint"
        id="dock2record"
        style={{ display: showDock2Record ? "block" : "none" }}
      >
        Record your video 👉
        <br />
        {dock2RecordAction}
      </span>
      <button className="pause" disabled style={{ display: "none" }}>
        <span className="svg">
          <PauseIcon />
        </span>
        <span>Pause</span>
      </button>
      <button className="resume" disabled style={{ display: "none" }}>
        <span className="svg">
          <ResumeIcon />
        </span>
        <span>Resume</span>
      </button>
      <button className="preview" disabled style={{ display: "none" }}>
        <span className="svg">
          <StopIcon />
        </span>
        <span>Stop</span>
      </button>
      <button className="stop" disabled style={{ display: "none" }}>
        <span className="svg">
          <StopIcon />
        </span>
        <span>Stop</span>
      </button>
      <button className="recordAgain" disabled style={{ display: "none" }}>
        <span className="svg">
          <AgainIcon />
        </span>
        <span>Record again</span>
      </button>
      <p id="audioButtons">
        <span className="radioGroup">
          <input
            defaultChecked={!audioEnabled}
            disabled
            id="audioOffOption"
            name="audio"
            type="radio"
            value="off"
          />
          <label htmlFor="audioOffOption">Audio off</label>
        </span>
        <span className="radioGroup">
          <input
            defaultChecked={audioEnabled}
            disabled
            id="audioOnOption"
            name="audio"
            type="radio"
            value="on"
          />
          <label htmlFor="audioOnOption">Audio on</label>
        </span>
      </p>
    </section>
  );
};

export default RecorderButtons;
