import { isError } from "lodash";

function toStatus(error: unknown) {
  let status = 400;

  if (isError(error) && "status" in error) {
    status = error.status ? Number(error.status) : status;
  }

  return status;
}

export default toStatus;
