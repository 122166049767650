import { isRouteErrorResponse, Link, useRouteError } from "react-router-dom";

import ErrorTitle from "../../components/ErrorBoundary/ErrorTitle";
import { getRootEmail } from "../../utils/options";

const reportErrorLink = `/?to=${getRootEmail()}&subject=Videomail 404`;

const NotFound = () => {
  const error = useRouteError();
  const errorResponse = isRouteErrorResponse(error) ? error : undefined;

  return (
    <>
      <ErrorTitle message="Oh no, sorry! Page not found" status={404} />
      {errorResponse && (
        <p className="code">
          Technical explanation:
          <br />
          {errorResponse.statusText}
        </p>
      )}
      <p>
        You can <Link to={reportErrorLink}>report back to us</Link> with your disappointed
        face.
      </p>
    </>
  );
};

export default NotFound;
