import { useCallback } from "react";

import type VideomailClientType from "../pages/recorder/types/VideomailClient";

const useAllowUnload = (videomailClient?: VideomailClientType) => {
  const allowUnload = useCallback(() => {
    if (!videomailClient) {
      return true;
    }

    if (videomailClient.isDirty()) {
      return false;
    }

    if (videomailClient.isRecording()) {
      return false;
    }

    return true;
  }, [videomailClient]);

  return allowUnload;
};

export default useAllowUnload;
