import { Link } from "react-router-dom";

import { getRootEmail } from "../../utils/options";

const reportErrorLink = `/?to=${getRootEmail()}&subject=Videomail Error`;

function ErrorContact() {
  return (
    <p>
      If you believe something is weird, please{" "}
      <Link to={reportErrorLink}>drop a videomail</Link>, thanks!
    </p>
  );
}

export default ErrorContact;
