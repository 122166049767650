import versions from "./versions";

const Changelog = () => (
  <>
    <h2>Changelog</h2>
    <ul id="changelog">
      {Array.from(versions).map(([number, version]) => {
        const link = `/versions/#${number}`;
        return (
          <li id={number} key={number}>
            <h3>
              <a href={link}>Version {number}</a>
              {version.current && <>&nbsp;[current]</>}
            </h3>
            <ul>
              {version.changes?.map((change, index) => (
                <li key={index}>[change] {change}</li>
              ))}
              {version.features?.map((feature, index) => (
                <li key={index}>[feature] {feature}</li>
              ))}
              {version.improvements?.map((improvement, index) => (
                <li key={index}>[improvement] {improvement}</li>
              ))}
              {version.bugfixes?.map((bugfix, index) => (
                <li key={index}>[bugfix] {bugfix}</li>
              ))}
            </ul>
          </li>
        );
      })}
    </ul>
  </>
);

export default Changelog;
