import { NavLink } from "react-router-dom";

import type { Videomail } from "../../../../common/types/Videomail";

import ForwardIcon from "./../../../../common/assets/vectors/forward.svg";
import ReplyIcon from "./../../../../common/assets/vectors/reply.svg";

interface VideomailFooterBrowserProps {
  videomail: Videomail;
}

const VideomailFooterBrowser = (props: VideomailFooterBrowserProps) => {
  const { videomail } = props;

  return (
    <p className="buttons">
      <NavLink className="reply button" to={videomail.replyUrl}>
        <>
          <span className="svg">
            <ReplyIcon />
          </span>
          <span>Reply</span>
        </>
      </NavLink>
      {videomail.replyAllUrl && (
        <NavLink className="replyAll button" to={videomail.replyAllUrl}>
          <>
            <span className="svg">
              <ReplyIcon />
            </span>
            <span>Reply All</span>
          </>
        </NavLink>
      )}
      <NavLink className="correct button" to={videomail.correctUrl}>
        <>
          <span className="svg">
            <ForwardIcon />
          </span>
          <span>Forward</span>
        </>
      </NavLink>
    </p>
  );
};

export default VideomailFooterBrowser;
