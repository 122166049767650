import { NavLink } from "react-router-dom";

import type { Videomail } from "../../../common/types/Videomail";

import EmailLink from "../../components/EmailLink";

interface VideomailHeaderProps {
  parentVideomail?: undefined | Videomail;
  videomail: Videomail;
}

const VideomailHeader = (props: VideomailHeaderProps) => {
  const { parentVideomail, videomail } = props;

  return (
    <header>
      <dl>
        {videomail.from && <dt>From</dt>}
        {videomail.from && (
          <dd>
            <EmailLink address={videomail.from} />
          </dd>
        )}

        {/* TO */}

        {videomail.sendingToPretty && <dt>Sending to</dt>}
        {videomail.sendingToPretty && (
          <dd>
            <EmailLink address={videomail.sendingToPretty} />
          </dd>
        )}

        {videomail.sentToPretty && <dt>Sent to</dt>}
        {videomail.sentToPretty && (
          <dd>
            <EmailLink address={videomail.sentToPretty} />
          </dd>
        )}

        {videomail.rejectedToPretty && <dt>Failed to send to</dt>}
        {videomail.rejectedToPretty && (
          <dd>
            <EmailLink address={videomail.rejectedToPretty} />
          </dd>
        )}

        {/* CC */}

        {videomail.sendingCcPretty && <dt>Copying to</dt>}
        {videomail.sendingCcPretty && (
          <dd>
            <EmailLink address={videomail.sendingCcPretty} />
          </dd>
        )}

        {videomail.sentCcPretty && <dt>Copied to</dt>}
        {videomail.sentCcPretty && (
          <dd>
            <EmailLink address={videomail.sentCcPretty} />
          </dd>
        )}

        {videomail.rejectedCcPretty && <dt>Failed to copy to</dt>}
        {videomail.rejectedCcPretty && (
          <dd>
            <EmailLink address={videomail.rejectedCcPretty} />
          </dd>
        )}

        {/* OTHERS */}

        {videomail.siteTitle && <dt>Site</dt>}
        {videomail.siteTitle && <dd>{videomail.siteTitle}</dd>}

        {videomail.sentDatePretty && <dt>Sent date</dt>}
        {videomail.sentDatePretty && (
          <dd>
            <time dateTime={videomail.sentDateIso}>{videomail.sentDatePretty}</time>
          </dd>
        )}

        {videomail.expiresAfterPretty && <dt>Best before</dt>}
        {videomail.expiresAfterPretty && (
          <dd>
            <time dateTime={videomail.expiresAfterIso}>
              {videomail.expiresAfterPretty}
            </time>
          </dd>
        )}

        {parentVideomail && <dt>In response to</dt>}
        {parentVideomail && (
          <dd>
            <NavLink to={`#${parentVideomail.alias}`}>{parentVideomail.subject}</NavLink>
          </dd>
        )}
      </dl>
      {videomail.subject && <h1 role="heading">{videomail.subject}</h1>}
    </header>
  );
};

export default VideomailHeader;
