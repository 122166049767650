// remember this is not the same as NODE_ENV!
export const Environ = {
  // these are our two remote servers
  DEV: "dev",

  // special case for local development only
  LOCAL: "local",

  PROD: "prod",
  // special case for unit tests only
  TEST: "test",
} as const;

// ... and these actually define the runtime mode of Node.js and are
// set either in package.json, via Jest or in the Dockerfile
export const NodeEnvType = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
} as const;
