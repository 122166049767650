import { NavLink, useLocation } from "react-router-dom";

import { homePath, whitelistPath } from "../../router";
import { getSlogan, getTitle } from "../../utils/options";

const Header = () => {
  const title = getTitle();
  const slogan = getSlogan();

  const location = useLocation();

  const isHome = location.pathname === homePath;
  const isWhitelist = location.pathname === whitelistPath;

  const showSlogan = isHome || isWhitelist;

  return (
    <header>
      <h1>
        <NavLink to="/">{title}</NavLink>
      </h1>

      {showSlogan && (
        <p id="slogan">
          {slogan}
          <NavLink to="/faq">more &hellip;</NavLink>
        </p>
      )}
    </header>
  );
};

export default Header;
