// These should be aligned with Pino:
// node_modules/pino/pino.d.ts
//
// type Level = "fatal" | "error" | "warn" | "info" | "debug" | "trace";

export enum LogLevel {
  DEBUG = "debug",
  ERROR = "error",
  FATAL = "fatal",
  INFO = "info",
  TRACE = "trace",
  WARN = "warn",
}
