import type { VideomailErrorData } from "../../../common/types/error";

import reportVideomailError from "./onAllErrors";
import toTrace from "./toTrace";

function onerror(event: ErrorEvent) {
  const errData: VideomailErrorData = {
    err: event.error,
    event,
    message: event.message,
    title: "onerror",
    trace: toTrace(event.filename, event.lineno, event.colno),
  };

  void reportVideomailError(errData);
}

window.addEventListener("error", onerror, false);
