import type { FullVideomailErrorData } from "../types/error";

const MESSAGES_TO_IGNORE = [
  "Limited height cannot be less than 1",
  "your browser is unable to use",
  "Webcam has no audio",
  "Starting audio failed",
  "Camera data is missing",
  "Requested device not found",
  "Unable to access webcam",
  "Data exchange interrupted",
  "Sorry, your browser cannot record from your mobile camera",
  "Sorry, your browser is unable to use webcams",
  "DOM object constructor cannot be called as a",
  "Please check your network connection and reload",
];

const EXPLANATIONS_TO_IGNORE = [
  "Cannot access your webcam",
  "Trying to reconnect every few seconds",
  "Sorry, connection to the server has been destroyed. Please reload",
  "A hardware error occurred which prevented access to your webcam",
];

function shouldIgnoreError(videomailErrorData: FullVideomailErrorData): boolean {
  /*
    BEWARE OF MESSAGE VS EXPLANATION
    First section is MESSAGE, second section is EXPLANATION
  */

  if (
    MESSAGES_TO_IGNORE.some((message2Ignore) =>
      videomailErrorData.message.toLowerCase().includes(message2Ignore.toLowerCase()),
    )
  ) {
    return true;
  }

  if (
    EXPLANATIONS_TO_IGNORE.some((explanation2Ignore) =>
      videomailErrorData.explanation
        ?.toLowerCase()
        .includes(explanation2Ignore.toLowerCase()),
    )
  ) {
    return true;
  }

  return false;
}

export default shouldIgnoreError;
