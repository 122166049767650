function getPrefixByStatus(statusCode: number) {
  let prefix;

  switch (statusCode) {
    case 400:
      prefix = "☢";
      break;
    case 403:
      prefix = "⛔";
      break;
    case 404:
      prefix = "☹";
      break;
    case 410:
    case 504:
      prefix = "⏰";
      break;
    case 416:
      prefix = "⏩";
      break;
    case 422:
      prefix = "❌";
      break;
    case 500:
      prefix = "☣";
      break;
    case 502:
    case 503:
      prefix = "⭕";
      break;
    default:
      prefix = "";
  }

  return prefix;
}

export default getPrefixByStatus;
