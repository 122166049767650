import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import VideomailClient from "videomail-client";

import type { Videomail } from "../../../../common/types/Videomail";
import type VideomailClientType from "./../types/VideomailClient";

import getVideomailClientOptions from "../../../utils/getVideomailClientOptions";
import Form from "./../form";
import { RecorderMode } from "./types";
const VIDEOMAIL_CLIENT_OPTIONS = getVideomailClientOptions();

const Correct = () => {
  const videomail = useLoaderData() as Videomail;

  const [defaultFrom, setDefaultFrom] = useState<string | undefined>();
  const [defaultTo, setDefaultTo] = useState<string[] | undefined>();
  const [defaultCc, setDefaultCc] = useState<string[] | undefined>();
  const [defaultBcc, setDefaultBcc] = useState<string[] | undefined>();
  const [defaultSubject, setDefaultSubject] = useState<string | undefined>();

  const vcRef = useRef<VideomailClientType>();

  // Avoid recreating on every render
  if (!vcRef.current) {
    vcRef.current = new VideomailClient(VIDEOMAIL_CLIENT_OPTIONS);
  }

  function onUnload() {
    if (!vcRef.current) {
      return;
    }

    vcRef.current.unload();
    vcRef.current = undefined;
  }

  useEffect(() => {
    if (!vcRef.current) {
      return;
    }

    // Because any failing or successful deliveries should be shown and
    // is very well handled on backend side: successful deliveries won't be sent again
    const to = _.union(videomail.to, videomail.sentTo, videomail.rejectedTo);
    const cc = _.union(videomail.cc, videomail.sentCc, videomail.rejectedCc);
    const bcc = _.union(videomail.bcc, videomail.sentBcc, videomail.rejectedBcc);

    setDefaultFrom(videomail.from);
    setDefaultTo(to);
    setDefaultCc(cc);
    setDefaultBcc(bcc);
    setDefaultSubject(videomail.subject);

    vcRef.current.replay(videomail);

    return () => {
      onUnload();
    };
  }, [vcRef.current, videomail]);

  return (
    <section id="recorder" role="application">
      <div id="videomail" />

      <Form
        defaultBcc={defaultBcc}
        defaultCc={defaultCc}
        defaultFrom={defaultFrom}
        defaultSubject={defaultSubject}
        defaultTo={defaultTo}
        mode={RecorderMode.CORRECT}
        vcRef={vcRef}
        videomailToCorrect={videomail}
      />
    </section>
  );
};

export default Correct;
