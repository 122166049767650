import { NavLink, useLoaderData } from "react-router-dom";

import type { Videomail } from "../../../common/types/Videomail";

const Report = () => {
  const videomail = useLoaderData() as Videomail;

  return (
    <section id="report">
      {videomail.sendingToPretty && (
        <h2>✉ Yay, sending to {videomail.sendingToPretty}</h2>
      )}
      {videomail.sendingCcPretty && <h2>✉ Copying to {videomail.sendingCcPretty}</h2>}
      {videomail.sentToPretty && <h2>✔ Already sent to {videomail.sentToPretty}</h2>}
      {videomail.sentCcPretty && <h2>✔ Already copied to {videomail.sentCcPretty}</h2>}
      {videomail.rejectedToPretty && (
        <h2>⚠ Failed to send to {videomail.rejectedToPretty}</h2>
      )}
      {videomail.rejectedCcPretty && (
        <h2>⚠ Failed to copy to {videomail.rejectedCcPretty}</h2>
      )}
      <h3>
        View it at this secret URL
        <br />
        <NavLink to={videomail.url}>{videomail.url}</NavLink>
      </h3>
      <div className="notes">
        <h4>Some notes</h4>
        <ul>
          <li>
            This videomail will expire in {videomail.expirationPretty} (
            {videomail.expiresAfterPretty})
          </li>
          <li>
            If sending failed due to a non-existing email address, you will be notified
          </li>
          <li>
            Worried about privacy? Don&rsquo;t be. Unless the recipient or you share the
            link in the wild, it is{" "}
            <NavLink to="/faq/#linksharingrisk">mission impossible for strangers</NavLink>{" "}
            to see your videomails.
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Report;
