// https://googlechrome.github.io/samples/promise-rejection-events/

import type { VideomailErrorData } from "../../../common/types/error";

import reportVideomailError from "./onAllErrors";
import toTrace from "./toTrace";

// https://developer.mozilla.org/en-US/docs/Web/API/PromiseRejectionEvent
function onunhandledrejection(event: PromiseRejectionEvent) {
  const errData: VideomailErrorData = {
    cause: event.reason.cause,
    event,
    message: event.reason.message,
    promise: event.promise,
    reason: event.reason,
    title: "unhandledrejection",
    trace: toTrace(event.reason.filename, event.reason.lineno, event.reason.colno),
  };

  void reportVideomailError(errData);
}

window.addEventListener("unhandledrejection", onunhandledrejection, false);
