import { UAParser } from "ua-parser-js";

import type {
  FullVideomailErrorData,
  VideomailErrorData,
} from "../../../common/types/error";

import isLocal from "../../../common/isLocal";
import isProd from "../../../common/isProd";
import shouldIgnoreError from "../../../common/util/shouldIgnoreError";
import api from "../api";
import logger from "../logger";

async function reportVideomailError(errData: VideomailErrorData) {
  try {
    if (!isProd()) {
      // Always print on local and dev environments
      logger.error(errData);
    }

    const uaParser = new UAParser();
    const device = uaParser.getDevice();
    const os = uaParser.getOS();
    const cpu = uaParser.getCPU();

    const extendedErrData: FullVideomailErrorData = {
      ...errData,
      browser: uaParser.getBrowser(),
      cookie: global.document.cookie.split("; ").join(",\n"),
      cpu: cpu.architecture ? cpu : undefined,
      device: device.type ? device : undefined,
      engine: uaParser.getEngine(),
      location: global.window.location.href,
      orientation:
        typeof screen.orientation === "string"
          ? screen.orientation
          : screen.orientation.type.toString(),
      os: os.name && os.version ? os : undefined,
      screen: [screen.width, screen.height, screen.colorDepth].join("×"),
    };

    if (shouldIgnoreError(extendedErrData)) {
      logger.debug(`Client error ignored: ${errData.message}`);
      return;
    }

    if (isLocal()) {
      logger.debug(
        `Not going to post client-error for local development: ${errData.message}`,
      );
      // Don't post errors for local development
      return;
    }

    await api.post("client-error", {
      json: extendedErrData,
    });

    if (isProd()) {
      gtag("event", "exception", { description: errData.message });
    }
  } catch (exc) {
    // Use console instead to avoid recursion
    console.error(exc);
  } finally {
    // make sure the developer sees any weird errors
    if (isLocal()) {
      global.window.alert(JSON.stringify(errData, undefined, 2));
    }
  }
}

export default reportVideomailError;
