import humanizeDuration from "humanize-duration";

function getExpirationPretty(expireSeconds: number) {
  const expirationPretty = humanizeDuration(expireSeconds * 1000, {
    round: true,
    units: "d",
  });

  return expirationPretty;
}

export default getExpirationPretty;
