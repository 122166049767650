import { useEffect } from "react";
import VideomailClient from "videomail-client";

import type { Videomail } from "../../../common/types/Videomail";

import getVideomailClientOptions from "../../utils/getVideomailClientOptions";
import VideomailBody from "./body";
import VideomailFooter from "./footer";
import VideomailHeader from "./header";

const options = getVideomailClientOptions();

interface VideomailViewProps {
  canRecord: boolean;
  latest?: boolean;
  parentVideomail?: undefined | Videomail;
  videomail: Videomail;
}

const VideomailView = (props: VideomailViewProps) => {
  const { canRecord, parentVideomail, videomail } = props;

  useEffect(() => {
    const videomailClient = new VideomailClient(options);
    videomailClient?.replay(videomail, videomail.alias);

    return () => {
      videomailClient?.unload();
    };
  }, [videomail]);

  return (
    <section className="videomail" id={videomail.alias}>
      <VideomailHeader parentVideomail={parentVideomail} videomail={videomail} />
      <main role="main">
        {videomail.body && <VideomailBody body={videomail.body} />}
        <div className="visuals" />
        <div className="tips">
          {videomail.expiresAfterPretty && (
            <p role="note">Save video? - Right-click on it before it expires</p>
          )}
          <p role="note">Share video responsibly? - Copy the secret link and paste it</p>
        </div>
      </main>
      <VideomailFooter canRecord={canRecord} videomail={videomail} />
      {parentVideomail && <hr />}
    </section>
  );
};

export default VideomailView;
