import getCachedValue from "../../../utils/getCachedValue";
import Record from "./record";
import { RecorderMode } from "./types";

const Create = () => {
  const defaultFrom = getCachedValue("from");
  const defaultTo = getCachedValue("to");
  const defaultSubject = getCachedValue("subject");

  return (
    <Record
      defaultFrom={defaultFrom}
      defaultSubject={defaultSubject}
      defaultTo={defaultTo ? [defaultTo] : undefined}
      mode={RecorderMode.CREATE}
    />
  );
};

export default Create;
