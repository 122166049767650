import { Link, useLoaderData } from "react-router-dom";

import type { Videomail } from "../../../../common/types/Videomail";

import useVideomailReplyRecipients from "../../../hooks/useVideomailReplyRecipients";
import Record from "./record";
import { RecorderMode } from "./types";

interface ReplyProps {
  replyAll?: boolean;
}

const Reply = (props: ReplyProps) => {
  const { replyAll } = props;

  const videomail = useLoaderData() as Videomail;

  const getVideomailReplyRecipients = useVideomailReplyRecipients(videomail, replyAll);
  const { defaultFrom, defaultTo } = getVideomailReplyRecipients();

  const defaultSubject = `RE: ${videomail.subject}`;

  const header = (
    <h2 className="response">
      Your response to: <Link to={videomail.url}>{videomail.subject}</Link>
    </h2>
  );

  return (
    <Record
      defaultFrom={defaultFrom}
      defaultSubject={defaultSubject}
      defaultTo={defaultTo}
      header={header}
      mode={RecorderMode.REPLY}
      parentKey={videomail.key}
    />
  );
};

export default Reply;
