import type { Dispatch, SetStateAction } from "react";

import { useCallback } from "react";
import isEmail from "validator/es/lib/isEmail";

const DELIMITER = /([\n\r,;])/u;

const useFindEmailAddress = (
  emails: string[],
  setEmails: Dispatch<SetStateAction<string[]>>,
  setInputValue: Dispatch<SetStateAction<string>>,
) => {
  const findEmailAddress = useCallback(
    (value: string, isEnter?: boolean) => {
      const validEmails: string[] = [];

      let newInputValue = "";

      const addEmails = (email: string) => {
        // Detect duplicates
        for (let i = 0, l = emails.length; i < l; i++) {
          if (emails[i]?.toLowerCase() === email.toLowerCase()) {
            return false;
          }
        }

        validEmails.push(email);
        return true;
      };

      if (value.length > 0) {
        if (DELIMITER.test(value)) {
          const setArr = new Set(
            value.split(DELIMITER).filter((n) => n.replace(DELIMITER, "")),
          );

          const arr = [...setArr];

          while (arr.length) {
            const isValid = isEmail(String(arr[0]?.trim()));

            if (isValid) {
              addEmails(String(arr.shift()?.trim()));
            } else {
              newInputValue = String(arr.shift());
            }
          }
        } else if (isEnter) {
          const isValid = isEmail(value);

          if (isValid) {
            addEmails(value);
          } else {
            newInputValue = value;
          }
        } else {
          newInputValue = value;
        }
      }

      setEmails([...emails, ...validEmails]);
      setInputValue(newInputValue);
    },
    [emails, setEmails, setInputValue],
  );

  return findEmailAddress;
};

export default useFindEmailAddress;
