import type { KeyboardEvent, SyntheticEvent } from "react";

import { clsx } from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import isEmail from "validator/es/lib/isEmail";

import useFindEmailAddress from "../../hooks/useFindEmailAddress";
import MultiEmailLabels from "./Labels";

interface MultiEmailProps {
  defaultValue: string[] | undefined;
  dockHint?: string;
  forceShowDock?: boolean;
  name: string;
  placeholder: string;
  readOnly?: boolean;
  show?: boolean;
  title: string;
}

function isEveryEmailValid(emails: string[]) {
  const valid = emails.every((email) => isEmail(email));

  return valid;
}

// Inspired by https://github.com/axisj/react-multi-email/tree/master/react-multi-email
const MultiEmail = (props: MultiEmailProps) => {
  const {
    defaultValue = [],
    dockHint,
    forceShowDock = false,
    name,
    placeholder,
    readOnly = false,
    show = true,
    title,
  } = props;

  const emailInputRef = useRef<HTMLInputElement>(null);
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState(false);
  const [emails, setEmails] = useState<string[]>(defaultValue);
  const [inputValue, setInputValue] = useState("");

  const allEmails = useMemo(
    () => [...emails, inputValue].filter(Boolean),
    [emails, inputValue],
  );

  const [isValid, setIsValid] = useState(isEveryEmailValid(allEmails));
  const [showDock, setShowDock] = useState(forceShowDock);

  const findEmailAddress = useFindEmailAddress(emails, setEmails, setInputValue);

  const removeEmail = useCallback(
    (index: number, isDisabled?: boolean) => {
      if (isDisabled) {
        return;
      }

      const newEmails = [...emails.slice(0, index), ...emails.slice(index + 1)];

      setEmails(newEmails);
    },
    [emails],
  );

  const handleOnBlur = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      setFocused(false);
      findEmailAddress(e.currentTarget.value, true);
    },
    [findEmailAddress],
  );

  const handleOnKeyup = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case "Enter":
          findEmailAddress(e.currentTarget.value, true);
          break;
        default:
      }
    },
    [findEmailAddress],
  );

  const handleOnKeydown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case "Enter":
          e.preventDefault();
          break;
        case "Backspace":
          if (!e.currentTarget.value) {
            removeEmail(emails.length - 1, false);
          }
          break;
        default:
      }
    },
    [emails.length, removeEmail],
  );

  const handleOnChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      findEmailAddress(e.currentTarget.value);
    },
    [findEmailAddress],
  );

  const handlePaste = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      const clipboardData = e.clipboardData;
      const pastedData = clipboardData.getData("Text");
      findEmailAddress(pastedData);
      e.preventDefault();
    },
    [findEmailAddress],
  );

  useEffect(() => {
    const isValid = isEveryEmailValid(allEmails);

    const validity = isValid ? "" : "Enter a valid email address";
    hiddenInputRef.current?.setCustomValidity(validity);

    setIsValid(isValid);

    hiddenInputRef.current?.dispatchEvent(
      new Event("input", {
        bubbles: true,
      }),
    );
  }, [allEmails]);

  return (
    <div style={{ display: show ? "block" : "none" }}>
      <input name={name} ref={hiddenInputRef} type="hidden" value={allEmails} />
      <div
        className={clsx("multi-email", { focused }, { readOnly }, { invalid: !isValid })}
        onClick={() => emailInputRef.current?.focus()}
      >
        <MultiEmailLabels emails={emails} removeEmail={removeEmail} />

        <input
          autoComplete="on"
          className="multi-email-input"
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onFocus={() => {
            setFocused(true);
          }}
          onKeyDown={handleOnKeydown}
          onKeyUp={handleOnKeyup}
          onMouseEnter={() => {
            setShowDock(true);
          }}
          onMouseLeave={() => {
            setShowDock(false);
          }}
          onPaste={handlePaste}
          placeholder={emails.length > 0 ? undefined : placeholder}
          readOnly={readOnly}
          ref={emailInputRef}
          spellCheck={false}
          title={title}
          type="text"
          value={inputValue}
        />
      </div>
      {showDock && dockHint && (
        <p className="dock hint" role="tooltip">
          <span>{dockHint}</span>
        </p>
      )}
    </div>
  );
};

export default MultiEmail;
