import type { NavigateFunction } from "react-router-dom";

import type { Videomail } from "../../../../../common/types/Videomail";

function onSubmitted(
  navigate: NavigateFunction,
  videomail: Videomail,
  nextUrl: string,
  cache: boolean,
) {
  if (cache) {
    if (videomail.from) {
      localStorage.setItem("from", videomail.from);
    }

    if (videomail.to) {
      localStorage.setItem("to", videomail.to);
    }

    if (videomail.cc) {
      localStorage.setItem("cc", videomail.cc);
    }

    if (videomail.bcc) {
      localStorage.setItem("bcc", videomail.bcc);
    }

    if (videomail.subject) {
      localStorage.setItem("subject", videomail.subject);
    }
  }

  navigate(nextUrl);
}

export default onSubmitted;
