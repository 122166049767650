import { NavLink } from "react-router-dom";

const WhitelistThanks = () => (
  <div id="whitelist-thanks">
    <h2>👍 Thanks for asking!</h2>
    <p>We have received your noble whitelist request.</p>
    <p>
      Rest assured we will process that within 48 hours and come back to you with a
      confirmation email which includes few more instructions to finalize the API for you.
    </p>
    <p>
      <NavLink to="/">◀ Return home</NavLink>
    </p>
  </div>
);

export default WhitelistThanks;
