interface MultiEmailLabelProps {
  email: string;
  index: number;
  removeEmail: (index: number) => void;
}

const MultiEmailLabel = (props: MultiEmailLabelProps) => {
  const { email, index, removeEmail } = props;

  return (
    <div data-label>
      <span>{email}</span>
      <button
        onClick={() => {
          removeEmail(index);
        }}
        title="Remove email address"
        type="button"
      >
        X
      </button>
    </div>
  );
};

export default MultiEmailLabel;
