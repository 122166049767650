// https://googlechrome.github.io/samples/promise-rejection-events/

import type { VideomailErrorData } from "../../../common/types/error";

import reportVideomailError from "./onAllErrors";
import toTrace from "./toTrace";

function onsecuritypolicyviolation(event: SecurityPolicyViolationEvent) {
  const errData: VideomailErrorData = {
    event,
    message: `blockedURI: ${event.blockedURI || ""}\n violatedDirective: ${event.violatedDirective} || ''\n originalPolicy: ${event.originalPolicy || "(no message"}`,
    title: "securitypolicyviolation",
    trace: toTrace(event.sourceFile, event.lineNumber, event.columnNumber),
  };

  void reportVideomailError(errData);
}

window.addEventListener("securitypolicyviolation", onsecuritypolicyviolation, false);
