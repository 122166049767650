import { Environ } from "./types/env";

function getEnviron() {
  if (!process.env.ENVIRON) {
    return Environ.LOCAL;
  }

  return process.env.ENVIRON;
}

export default getEnviron;
