import type { ReactNode } from "react";

interface EmailLinkProps {
  address: string;
  child?: ReactNode;
  className?: string;
  subject?: string | undefined;
}

const EmailLink = (props: EmailLinkProps) => {
  const { address, child, className, subject } = props;

  let href = `mailto:${address}`;

  if (subject) {
    href += `?subject=RE: ${subject}`;
  }

  return (
    <a className={className} href={href} role="button">
      {child ? child : address}
    </a>
  );
};

export default EmailLink;
