import type { Level, LogEvent } from "pino";

import { pino } from "pino";

import type { VideomailErrorData } from "../../../common/types/error";

import isLocal from "../../../common/isLocal";
import isProd from "../../../common/isProd";
import { LogLevel } from "../../../common/types/logLevel";
import reportVideomailError from "../errors/onAllErrors";

async function sendMessage(message) {
  const errData: VideomailErrorData = {
    message: message.msg,
    title: message.name,
    trace: message.stack,
  };

  await reportVideomailError(errData);
}

const logger = pino({
  browser: {
    transmit: {
      level: isLocal() ? LogLevel.ERROR : LogLevel.WARN,
      send: (_level: Level, logEvent: LogEvent) => {
        logEvent.messages.forEach((message) => {
          void sendMessage(message);
        });
      },
    },
  },
  formatters: {
    level: (label) => ({ level: label.toUpperCase() }),
  },
  level: isProd() ? LogLevel.WARN : LogLevel.TRACE,
});

export default logger;
