import { Link, NavLink } from "react-router-dom";

import { getVersion } from "../../utils/options";

const Footer = () => {
  const version = getVersion();
  const versionsLink = `/versions/#${version}`;

  return (
    <footer>
      <ul>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/faq/">FAQ</NavLink>
        </li>
        <li>
          <NavLink to={versionsLink}> Version v{version}</NavLink>
        </li>
        <li>
          <Link reloadDocument to="//binarykitchen.com">
            Contact
          </Link>
        </li>
      </ul>
      <p id="credits">
        For a talk overrated world by{" "}
        <Link id="bk" reloadDocument to="//binarykitchen.com">
          Binary Kitchen
        </Link>
        <Link id="dr" reloadDocument to="http://www.deafradio.co.nz">
          Deafradio
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
