import { ScrollRestoration, useLocation, useMatches, useOutlet } from "react-router-dom";

import { viewRouteId, whitelistThanksPath } from "../../router";
import Footer from "./Footer";
import Header from "./Header";

const Layout = () => {
  const location = useLocation();
  const matches = useMatches();
  const currentOutlet = useOutlet();

  const match = matches[matches.length - 1];

  const isViewing = match?.id === viewRouteId;
  const isWhitelistThanks = location.pathname === whitelistThanksPath;

  const showHeader = !isWhitelistThanks && !isViewing;
  const showFooter = !isViewing;

  return (
    <>
      {showHeader && <Header />}
      <main>{currentOutlet}</main>
      <ScrollRestoration />
      {showFooter && <Footer />}
    </>
  );
};

export default Layout;
