import type VideomailClientOptions from "../pages/recorder/types/VideomailClientOptions";

import isDev from "../../common/isDev";
import isLocal from "../../common/isLocal";
import isProd from "../../common/isProd";
import isProductionMode from "../../common/isProductionMode";
import {
  getAPIUrl,
  getImageQuality,
  getSocketUrl,
  getVideoFps,
  getVideoHeight,
  getVideoLimitSeconds,
  getVideoWidth,
} from "./options";

function getVideomailClientOptions() {
  const options: VideomailClientOptions = {
    adjustFormOnBrowserError: true,
    audio: {
      enabled: false,
      switch: true,
    },
    baseUrl: getAPIUrl(),
    enableAutoPause: isProductionMode(),
    enableAutoUnload: false,
    image: {
      quality: getImageQuality(),
    },

    notifier: {
      entertain: true,
      entertainClass: "b",
      entertainInterval: 6200,
      entertainLimit: 13,
    },

    PRODUCTION: isProd(),

    reportErrors: true,

    selectors: {
      formId: "email",
      keyInputName: "key",
      parentKeyInputName: "parentKey",
    },

    siteName: "videomail.io",

    socketUrl: getSocketUrl(),

    text: {
      audioOff: "Audio off",
      buttons: {
        preview: "Stop",
      },
    },
    verbose: isLocal() || isDev(),
    video: {
      countdown: isLocal() ? 0 : 3,
      facingModeButton: true,
      fps: getVideoFps(),
      height: getVideoHeight(),
      limitSeconds: getVideoLimitSeconds(),
      width: getVideoWidth(),
    },
  };

  return options;
}

export default getVideomailClientOptions;
