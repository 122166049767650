import type { JsonObject } from "../types/json";

type AbstractErrorDetails = JsonObject | number | string | undefined;

export interface AbstractErrorOptions extends ErrorOptions {
  details?: AbstractErrorDetails | undefined;
}

abstract class AbstractError extends Error {
  public code: string | undefined;
  public details?: AbstractErrorDetails | undefined;
  public errno: string | undefined;
  public status: number | undefined;

  public type: string | undefined;

  public constructor(message?: string, options?: AbstractErrorOptions, status?: number) {
    super(message, options);

    this.status = status;

    if (options?.details) {
      this.details = options.details;
    }

    const stackTarget = options?.cause || this;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    Error.captureStackTrace(stackTarget, AbstractError);
  }

  public setDetail(key: string, value) {
    if (!this.details) {
      this.details = {};
    }

    this.details[key] = value;
  }
}

export default AbstractError;
