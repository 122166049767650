import type ClientError from "../../../common/errors/client";

import isProd from "../../../common/isProd";
import ErrorContact from "../../components/ErrorBoundary/ErrorContact";
import ErrorTitle from "../../components/ErrorBoundary/ErrorTitle";

interface ViewErrorProps {
  error: ClientError;
}

const ViewError = (props: ViewErrorProps) => {
  const { error } = props;

  const status = error.status ?? 400;

  return (
    <>
      <ErrorTitle message="Sorry, unable to show videomail" status={status} />
      <h4>Possible reasons</h4>
      <ul>
        <li>The videomail has expired</li>
        <li>The link is wrong</li>
      </ul>
      {!isProd() && error.stack && <pre>{error.stack}</pre>}
      <ErrorContact />
    </>
  );
};

export default ViewError;
