import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "./assets/styl/site.styl";
import router from "./router";
import "./utils/errors/onSecurityPolicyViolation";
import "./utils/errors/onUnhandledRejection";
import "./utils/errors/onWindowError";

const container = document.getElementById("root");

if (!container) {
  throw new Error("Root container is missing");
}

/*
  TODO Just a note before I forget. To not rely on PayPal and use Stripe:
  https://github.com/binarykitchen/videomail.io/issues/169
*/

const root = createRoot(container);
root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
);
