import { isError } from "lodash";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

import isProd from "../../../common/isProd";
import toStatus from "../../utils/errors/toStatus";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import ErrorContact from "./ErrorContact";
import ErrorTitle from "./ErrorTitle";

// This is for any errors which haven't been processed yet
function ErrorBoundary() {
  const error = useRouteError();
  const status = toStatus(error);

  let outlet;

  if (isRouteErrorResponse(error)) {
    outlet = (
      <div>
        <ErrorTitle message={error.statusText} status={status} />
        {error.data && <h3>{error.data}</h3>}
      </div>
    );
  } else if (isError(error)) {
    outlet = (
      <div>
        <ErrorTitle message={error.message} status={status} />
        {!isProd() && error.stack && <pre>{error.stack}</pre>}
      </div>
    );
  }

  return (
    <>
      <Header />
      <main>
        {outlet}
        <ErrorContact />
      </main>
      <Footer />
    </>
  );
}

export default ErrorBoundary;
