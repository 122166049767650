import type { ReactNode } from "react";

import { useCallback, useEffect, useRef, useState } from "react";
import { useBeforeUnload } from "react-router-dom";
import VideomailClient from "videomail-client";

import type { JsonObject } from "../../../../common/types/json";
import type VideomailClientType from "../types/VideomailClient";

import useAllowUnload from "../../../hooks/useAllowUnload";
import useVideomailClientBlocker from "../../../hooks/useVideomailClientBlocker";
import getVideomailClientOptions from "../../../utils/getVideomailClientOptions";
import RecorderButtons from "../buttons/recorder";
import Form from "../form";
import { RecorderMode } from "./types";

const LEAVE_PROMPT =
  "You haven't sent your recorded videomail yet.\nIt is really okay to leave?";

const VIDEOMAIL_CLIENT_OPTIONS = getVideomailClientOptions();

interface RecordProps {
  defaultFrom?: string | undefined;
  defaultSubject?: string | undefined;
  defaultTo?: string[] | undefined;
  header?: ReactNode;
  mode: RecorderMode;
  parentKey?: string | undefined;
}

const Record = (props: RecordProps) => {
  const { defaultFrom, defaultSubject, defaultTo, header, mode, parentKey } = props;

  const vcRef = useRef<VideomailClientType>();

  const [showDock2Record, setShowDock2Record] = useState(false);

  // Avoid recreating on every render
  if (!vcRef.current) {
    vcRef.current = new VideomailClient(VIDEOMAIL_CLIENT_OPTIONS);
  }

  function onUnload() {
    if (!vcRef.current) {
      return;
    }

    vcRef.current.unload();
    vcRef.current = undefined;
  }

  useEffect(() => {
    if (!vcRef.current) {
      return;
    }

    // Do not neglect this part, this improves usability a lot, helps users where to click
    vcRef.current.on(
      VideomailClient.Events.INVALID,
      (_whyInvalid: string, invalidData?: JsonObject) => {
        const videoMissing =
          invalidData && "key" in invalidData && invalidData.key === undefined;

        setShowDock2Record(Boolean(videoMissing));
      },
    );

    vcRef.current.on(VideomailClient.Events.COUNTDOWN, () => {
      setShowDock2Record(false);
    });

    vcRef.current.on(VideomailClient.Events.RECORDING, () => {
      setShowDock2Record(false);
    });

    vcRef.current.on(VideomailClient.Events.VALID, () => {
      setShowDock2Record(false);
    });

    vcRef.current.on(VideomailClient.Events.DISCONNECTED, () => {
      setShowDock2Record(false);
    });

    vcRef.current.show();
    setShowDock2Record(mode === RecorderMode.REPLY);

    return () => {
      onUnload();
    };
  }, [mode, vcRef.current]);

  const allowUnload = useAllowUnload(vcRef.current);
  const blocker = useVideomailClientBlocker(vcRef.current);

  useEffect(() => {
    if (blocker.state === "blocked") {
      const goAway = window.confirm(LEAVE_PROMPT);

      if (goAway) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  // This is for when totally leaving videomail, to another URL
  useBeforeUnload(
    useCallback(
      (event: BeforeUnloadEvent) => {
        if (allowUnload()) {
          onUnload();
        } else {
          event.preventDefault();
        }
      },
      [allowUnload],
    ),
  );

  return (
    <section id="recorder" role="application">
      {header}
      <div id="videomail">
        <div id="visuals"></div>
        <RecorderButtons
          audioEnabled={VIDEOMAIL_CLIENT_OPTIONS.audio.enabled}
          mode={mode}
          showDock2Record={showDock2Record}
        />
      </div>
      <Form
        defaultFrom={defaultFrom}
        defaultSubject={defaultSubject}
        defaultTo={defaultTo}
        mode={mode}
        parentKey={parentKey}
        vcRef={vcRef}
      />
    </section>
  );
};

export default Record;
