import getPrefixByStatus from "../../utils/getPrefixByHttpStatus";

interface ErrorTitleProps {
  message: string;
  status: number;
}

function ErrorTitle(props: ErrorTitleProps) {
  const { message, status } = props;

  const id = `e${status}`;
  const prefix = getPrefixByStatus(status);

  return (
    <h2 id={id}>
      {prefix} {message} [{status}]
    </h2>
  );
}

export default ErrorTitle;
