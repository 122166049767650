import Linkify from "linkify-react";

interface VideomailBodyProps {
  body: string;
}

const VideomailBody = (props: VideomailBodyProps) => {
  const { body } = props;

  return (
    <Linkify as="p" className="body">
      {body}
    </Linkify>
  );
};

export default VideomailBody;
