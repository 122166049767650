import type { ChangeEvent } from "react";

import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import VideomailClient from "videomail-client";

import type { VideomailErrorData } from "../../../../common/types/error";
import type { Videomail } from "../../../../common/types/Videomail";
import type VideomailClientType from "../types/VideomailClient";

import MultiEmail from "../../../components/MultiEmail";
import { RecorderMode } from "../mode/types";
import EmailIcon from "./../../../../common/assets/vectors/email.svg";
import onSubmitted from "./events/onSubmitted";

const DEFAULT_DOCK_2_TO_HINT =
  "Send to more than one person? Hit ENTER or add a comma in-between.";
const CORRECT_DOCK_2_TO_HINT = "Correct email address(es) before resending";

const DEFAULT_DOCK_2_CC_HINT =
  "Send a copy to more than one person? Add a comma in-between.";
const CORRECT_DOCK_2_CC_HINT = "Correct email address(es) before resending";

const DEFAULT_DOCK_2_BCC_HINT =
  "Send a blind copy to more than one person? Add a comma in-between.";
const CORRECT_DOCK_2_BCC_HINT = "Correct email address(es) before resending";

interface FormProps {
  defaultBcc?: string[] | undefined;
  defaultCc?: string[] | undefined;
  defaultFrom?: string | undefined;
  defaultKey?: string | undefined;
  defaultSubject?: string | undefined;
  defaultTo?: string[] | undefined;
  mode: RecorderMode;
  parentKey?: string | undefined;
  vcRef: React.MutableRefObject<undefined | VideomailClientType>;
  videomailToCorrect?: undefined | Videomail;
}

/*
  Remember, videomail-client already comes with form validation, so cannot
  use react-form-hook here, or else all gets messed up.
*/
const Form = (props: FormProps) => {
  const {
    defaultBcc,
    defaultCc,
    defaultFrom,
    defaultSubject,
    defaultTo,
    mode,
    parentKey,
    vcRef,
    videomailToCorrect,
  } = props;
  const navigate = useNavigate();

  const isCorrecting = mode === RecorderMode.CORRECT;
  const isCreating = mode === RecorderMode.CREATE;

  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const [videomailKey, setVideomailKey] = useState<string | undefined>(
    videomailToCorrect?.key,
  );

  const [showCc, setShowCc] = useState(isCorrecting);
  const [showBcc, setShowBcc] = useState(isCorrecting);
  const [showDock2Submit, setShowDock2Submit] = useState(false);

  const handleOnSendWrapperMouseEnter = () => {
    setShowDock2Submit(true);
  };
  const handleOnSendWrapperMouseLeave = () => {
    setShowDock2Submit(false);
  };

  useEffect(() => {
    if (!vcRef.current) {
      return;
    }

    vcRef.current.on(
      VideomailClient.Events.SUBMITTED,
      (videomail: Videomail, { nextUrl }) => {
        onSubmitted(navigate, videomail, nextUrl, isCreating);
      },
    );

    vcRef.current.on(VideomailClient.Events.INVALID, (errorMsg: string, _invalidData) => {
      setErrorMsg(errorMsg);
    });

    vcRef.current.on(VideomailClient.Events.ERROR, (err: VideomailErrorData) => {
      setErrorMsg(err.message);
    });

    vcRef.current.on(VideomailClient.Events.VALID, () => {
      setErrorMsg(undefined);
    });
  }, [isCreating, navigate, vcRef.current]);

  useEffect(() => {
    if (errorMsg) {
      // Do not overwrite any existing error message
      return;
    }

    if (!videomailKey && !isCorrecting) {
      setErrorMsg("Let's record a video first 👈");
    }
  }, [errorMsg, videomailKey, isCorrecting]);

  const submitButtonTitle = isCorrecting ? "Resend videomail" : "Send videomail";

  return (
    <form id="email">
      <fieldset>
        {/* The is needed for POST and PUT on a Videomail resource itself */}
        <input
          defaultValue={videomailKey}
          name="key"
          // This to get any new keys and keep them in state during renders
          onInput={(evt: ChangeEvent<HTMLInputElement>) => {
            setVideomailKey(evt.target.value);
          }}
          readOnly={isCorrecting}
          required
          type="hidden"
        />
        {!isCorrecting && parentKey && (
          <input defaultValue={parentKey} name="parentKey" readOnly type="hidden" />
        )}
        <input
          autoComplete={!isCorrecting ? "on" : "off"}
          autoFocus={!isCorrecting}
          defaultValue={defaultFrom}
          name="from"
          placeholder="From (your email address)"
          required
          spellCheck={false}
          title="From (your email address)"
          type="email"
        />
        <div className="dockReference">
          <ul id="copiesButtons">
            {!showCc && (
              <li>
                <button
                  onClick={() => {
                    setShowCc(true);
                  }}
                  title="Add CC recipients (optional)"
                  type="button"
                >
                  CC
                </button>
              </li>
            )}
            {!showBcc && (
              <li>
                <button
                  onClick={() => {
                    setShowBcc(true);
                  }}
                  title="Add BCC recipients (optional)"
                  type="button"
                >
                  BCC
                </button>
              </li>
            )}
          </ul>
          <MultiEmail
            defaultValue={defaultTo}
            dockHint={isCorrecting ? CORRECT_DOCK_2_TO_HINT : DEFAULT_DOCK_2_TO_HINT}
            forceShowDock={!_.isEmpty(videomailToCorrect?.rejectedTo)}
            name="to"
            placeholder="To (recipient email addresses)"
            title="To (recipient email addresses)"
          />
        </div>
        <div className="dockReference">
          <MultiEmail
            defaultValue={defaultCc}
            dockHint={isCorrecting ? CORRECT_DOCK_2_CC_HINT : DEFAULT_DOCK_2_CC_HINT}
            forceShowDock={!_.isEmpty(videomailToCorrect?.rejectedCc)}
            name="cc"
            placeholder="CC email addresses"
            show={showCc}
            title="CC (email addresses for copies)"
          />
        </div>
        <div className="dockReference">
          <MultiEmail
            defaultValue={defaultBcc}
            dockHint={isCorrecting ? CORRECT_DOCK_2_BCC_HINT : DEFAULT_DOCK_2_BCC_HINT}
            forceShowDock={!_.isEmpty(videomailToCorrect?.rejectedBcc)}
            name="bcc"
            placeholder="BCC email addresses"
            show={showBcc}
            title="BCC (email addresses for blind copies)"
          />
        </div>
        <input
          autoComplete={!isCorrecting ? "on" : "off"}
          defaultValue={defaultSubject}
          name="subject"
          placeholder="Subject"
          readOnly={isCorrecting}
          required
          type="text"
        />
        {videomailToCorrect?.body && (
          <p className="body readOnly">{videomailToCorrect.body}</p>
        )}
        {!videomailToCorrect && (
          <textarea name="body" placeholder="Message (optional)" rows={3} />
        )}
        <div className="dockReference">
          <div
            id="sendWrapper"
            onMouseEnter={handleOnSendWrapperMouseEnter}
            onMouseLeave={handleOnSendWrapperMouseLeave}
          >
            <button disabled={Boolean(errorMsg)} id="send" type="submit">
              <span className="svg">
                <EmailIcon />
              </span>
              <span>{submitButtonTitle}</span>
            </button>
          </div>
          {showDock2Submit && errorMsg && (
            <p className="hint" id="dock2Submit" role="tooltip">
              <span>{errorMsg}</span>
            </p>
          )}
        </div>
      </fieldset>
    </form>
  );
};

export default Form;
