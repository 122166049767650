const videomailOptions = process.env.VIDEOMAIL_OPTIONS;

function getTitle() {
  return videomailOptions.title;
}

function getSlogan() {
  return videomailOptions.slogan;
}

function getRootEmail() {
  return videomailOptions.rootEmail;
}

function getVersion() {
  return videomailOptions.version;
}

function getExpireSeconds() {
  return videomailOptions.expireSeconds;
}

function getAPIUrl() {
  return videomailOptions.apiUrl;
}

function getClientUrl() {
  return videomailOptions.clientUrl;
}

function getSocketUrl() {
  return videomailOptions.socketUrl;
}

function getVideoFps() {
  return videomailOptions.videoFps;
}

function getVideoWidth() {
  return videomailOptions.videoWidth;
}

function getVideoHeight() {
  return videomailOptions.videoHeight;
}

function getVideoLimitSeconds() {
  return videomailOptions.videoLimitSeconds;
}

function getImageQuality() {
  return videomailOptions.imageQuality;
}

function getGoogleTagID() {
  return videomailOptions.googleTagID;
}

export {
  getAPIUrl,
  getClientUrl,
  getExpireSeconds,
  getImageQuality,
  getRootEmail,
  getSlogan,
  getSocketUrl,
  getTitle,
  getVersion,
  getVideoFps,
  getVideoHeight,
  getVideoLimitSeconds,
  getVideoWidth,
  getGoogleTagID,
};
