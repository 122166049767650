interface Version {
  bugfixes?: string[];
  changes?: string[];
  current?: boolean;
  features?: string[];
  improvements?: string[];
}

const versions = new Map<string, Version>();

versions.set("2.0.0", {
  changes: [
    "Massive overhaul: the Videomail engine runs a lot faster",
    "Technology switch: no more AngularJS, all functional, using React.js, TypeScript and latest Node.js LTS",
  ],
  current: true,
  improvements: [
    "Email addresses: much more support (chips) including copy-paste features",
    "Tons of minor UI improvements including cosmetics and funky CSS transitions",
  ],
});

versions.set("1.x.x", {
  changes: [
    "Started in 2013, using AngularJS, WebSockets and CommonJS. All the old versions are stored on GitHub.",
  ],
});

export default versions;
