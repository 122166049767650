import { useCallback } from "react";
import { type BlockerFunction, useBlocker } from "react-router-dom";

import type VideomailClientType from "../pages/recorder/types/VideomailClient";

import useAllowUnload from "./useAllowUnload";

const useVideomailClientBlocker = (videomailClient?: VideomailClientType) => {
  const allowUnload = useAllowUnload(videomailClient);

  const shouldBlock = useCallback<BlockerFunction>(
    (args) => {
      const { currentLocation, nextLocation } = args;

      if (currentLocation.pathname === nextLocation.pathname) {
        return false;
      }

      // Block when user hasn't completed yet
      return !allowUnload();
    },
    [allowUnload],
  );

  return useBlocker(shouldBlock);
};

export default useVideomailClientBlocker;
