import type { Videomail } from "../../../../common/types/Videomail";

import VideomailFooterBrowser from "./browser";
import VideomailFooterStatic from "./static";

interface VideomailFooterProps {
  canRecord: boolean;
  videomail: Videomail;
}

const VideomailFooter = (props: VideomailFooterProps) => {
  const { canRecord, videomail } = props;

  return (
    <footer>
      {canRecord ? (
        <VideomailFooterBrowser videomail={videomail} />
      ) : (
        <VideomailFooterStatic videomail={videomail} />
      )}
    </footer>
  );
};

export default VideomailFooter;
