import { memo } from "react";

import MultiEmailLabel from "./Label";

interface LabelsProps {
  emails: string[];
  removeEmail: (index: number) => void;
}

const MultiEmailLabels = (props: LabelsProps) => {
  const { emails, removeEmail } = props;

  return (
    <>
      {emails.map((email: string, index: number) => (
        <MultiEmailLabel
          email={email}
          index={index}
          key={index}
          removeEmail={removeEmail}
        />
      ))}
    </>
  );
};

export default memo(MultiEmailLabels);
