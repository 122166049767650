import { pull, uniq } from "lodash";
import { useCallback } from "react";

import type { Videomail } from "../../common/types/Videomail";

import getAllPossibleRecipients from "../../common/util/getAllPossibleRecipients";
import getCachedValue from "../utils/getCachedValue";

const useVideomailReplyRecipients = (videomail: Videomail, replyAll?: boolean) => {
  const getVideomailReplyRecipients = useCallback(() => {
    let defaultTo = [videomail.from];
    let defaultFrom: string | undefined;

    const allPossibleRecipients = getAllPossibleRecipients(videomail);
    const requiresReplyAll = replyAll && allPossibleRecipients.length > 1;
    const rejectedTo = videomail.rejectedTo;

    if (allPossibleRecipients.length > 0) {
      if (requiresReplyAll) {
        allPossibleRecipients.push(videomail.from);

        defaultTo = uniq(allPossibleRecipients);
      } else {
        defaultFrom = allPossibleRecipients[0];
      }
    } else if (Array.isArray(rejectedTo) && rejectedTo.length === 1) {
      defaultFrom = rejectedTo[0];
    } else if (videomail.sending) {
      // User must be in a hurry and might want to already reply to an unprocessed videomail
      defaultFrom = videomail.to;
    }

    if (!defaultFrom) {
      defaultFrom = getCachedValue("from");
    }

    if (defaultFrom) {
      // Ensure I'm not sending to myself if I exist in both
      defaultTo = pull(defaultTo, defaultFrom);
    }

    return { defaultFrom, defaultTo };
  }, [replyAll, videomail]);

  return getVideomailReplyRecipients;
};

export default useVideomailReplyRecipients;
