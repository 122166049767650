import type { MetaTagsConfig } from "react-metatags-hook";

import { useCallback, useEffect, useRef, useState } from "react";
import { useMetaTags } from "react-metatags-hook";
import { useLoaderData } from "react-router-dom";
import VideomailClient from "videomail-client";

import type { Videomail, VideomailError } from "../../../common/types/Videomail";
import type VideomailClientType from "../recorder/types/VideomailClient";

import ClientError from "../../../common/errors/client";
import toStatus from "../../utils/errors/toStatus";
import getVideomailClientOptions from "../../utils/getVideomailClientOptions";
import { getTitle } from "../../utils/options";
import ViewError from "./error";
import VideomailView from "./videomail";

const VIDEOMAIL_CLIENT_OPTIONS = getVideomailClientOptions();

// Converted callback style to promise
function loadVideomail(
  key: string,
  videomailClient: undefined | VideomailClientType,
): Promise<undefined | Videomail> {
  return new Promise((resolve, reject) => {
    if (!videomailClient) {
      resolve(undefined);
    } else {
      videomailClient.getByKey(key, (err?: VideomailError, videomail?: Videomail) => {
        if (err) {
          reject(err);
        } else {
          resolve(videomail);
        }
      });
    }
  });
}

const View = () => {
  const latestVideomail = useLoaderData() as Videomail;

  const [error, setError] = useState<ClientError | undefined>(undefined);
  const [videomails, setVideomails] = useState<Videomail[]>([]);
  const [canRecord, setCanRecord] = useState(false);

  const vcRef = useRef<VideomailClientType>();

  // Avoid recreating on every render
  if (!vcRef.current) {
    vcRef.current = new VideomailClient(VIDEOMAIL_CLIENT_OPTIONS);
  }

  const metaConfig: MetaTagsConfig = {};

  if (latestVideomail.subject) {
    metaConfig.title = `${getTitle()} - ${latestVideomail.subject}`;
  }

  useMetaTags(metaConfig, [latestVideomail]);

  function onUnload() {
    if (!vcRef.current) {
      return;
    }

    vcRef.current.unload();
    vcRef.current = undefined;

    setVideomails([]);
  }

  useEffect(() => {
    if (!vcRef.current) {
      return;
    }

    setCanRecord(vcRef.current.canRecord());

    const newVideomails = [latestVideomail];
    setVideomails(newVideomails);

    return () => {
      onUnload();
    };
  }, [vcRef.current, latestVideomail]);

  useEffect(() => {
    const parentVideomail = videomails[videomails.length - 1];
    const parentKey = parentVideomail?.parentKey;

    // Grab the next parent
    async function loadParentVideomail() {
      try {
        if (!parentKey) {
          // Stop looping
          return;
        }

        const parentVideomail = await loadVideomail(parentKey, vcRef.current);

        if (parentVideomail) {
          const newVideomails = [...videomails, parentVideomail];
          setVideomails(newVideomails);
        }
      } catch (exc) {
        const err = exc as Error;

        setError(new ClientError(err.message, { cause: err }, toStatus(err)));
      }
    }

    void loadParentVideomail();
  }, [videomails]);

  const findVideomailByKey = useCallback(
    (key: string) => videomails.find((videomail) => videomail.key === key),
    [videomails],
  );

  if (error) {
    return <ViewError error={error} />;
  }

  if (videomails.length < 1) {
    return <></>;
  }

  return (
    <ol id="videomails">
      {videomails.map((videomail, index) => {
        const parentVideomail = videomail.parentKey
          ? findVideomailByKey(videomail.parentKey)
          : undefined;
        return (
          <li key={videomail.alias} style={{ ["--index" as string]: index }}>
            <VideomailView
              canRecord={canRecord}
              parentVideomail={parentVideomail}
              videomail={videomail}
            />
          </li>
        );
      })}
    </ol>
  );
};

export default View;
