import type { Videomail } from "../../../../common/types/Videomail";

import EmailLink from "../../../components/EmailLink";
import ReplyIcon from "./../../../../common/assets/vectors/reply.svg";

interface VideomailFooterStaticProps {
  videomail: Videomail;
}

const VideomailFooterStatic = (props: VideomailFooterStaticProps) => {
  const { videomail } = props;

  return (
    <>
      <p className="buttons">
        <EmailLink
          address={videomail.from}
          child={
            <>
              <span className="svg">
                <ReplyIcon />
              </span>
              <span>Reply by email</span>
            </>
          }
          className="reply button"
          subject={videomail.subject}
        />
      </p>
      <p className="note" role="note">
        Your browser is unable to record a video. Hence you can only reply by email.
      </p>
    </>
  );
};

export default VideomailFooterStatic;
