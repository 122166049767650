import type { SubmitHandler } from "react-hook-form";

import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { whitelistThanksPath } from "../../router";
import api from "../../utils/api";
import logger from "../../utils/logger";

interface WhitelistEntry {
  comments: string;
  email: string;
  enableAudio: boolean;
  forWordpress: boolean;
  origins: string;
  siteName: string;
}

const Whitelist = () => {
  const {
    // formState is a proxy, have to subscribe it here for each state
    formState: { isDirty, isSubmitting, isValid },
    handleSubmit,
    register,
  } = useForm<WhitelistEntry>({
    mode: "onChange",
    shouldUseNativeValidation: true,
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<WhitelistEntry> = async (whitelistEntry, event) => {
    try {
      event?.preventDefault();

      await api.post("whitelist", {
        json: whitelistEntry,
      });

      navigate(whitelistThanksPath);
    } catch (exc) {
      logger.error(exc);
    }
  };

  const disableSubmitButton = isSubmitting || !isDirty || !isValid;

  return (
    <div id="whitelist">
      <h2>Whitelist Request</h2>
      <p>
        Want to run Videomail on your live site? Just fill out the details below and we
        will whitelist that.
      </p>
      <form name="whitelistForm" onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <input
            autoComplete="on"
            autoFocus
            placeholder="Your email address"
            required
            spellCheck={false}
            type="email"
            {...register("email", {
              required: true,
            })}
          />
          <input
            id="siteName"
            placeholder="Name of your site"
            required
            type="text"
            {...register("siteName", { required: true })}
          />
          <p className="example">
            Type a name for referencing, in most cases your company name.
          </p>
          <textarea
            id="origins"
            placeholder="URLs to whitelist"
            required
            rows={4}
            {...register("origins", { required: true })}
          ></textarea>
          <p className="example">
            Just separate valid base URLs with commas, i.e.
            &ldquo;https://www.my-site.com, http://local.my-site.com:6000&rdquo; - no need
            to specify paths.
          </p>
          <div id="enableAudioWrapper">
            <input
              id="enableAudioBox"
              type="checkbox"
              value="true"
              {...register("enableAudio")}
            />
            <label htmlFor="enableAudioBox">Enable Audio recording?</label>
          </div>
          <p className="example">
            Asking this because Sign Language does not need audio and is way more cool ;)
          </p>
          <div id="wordPressBoxWrapper">
            <input
              id="wordpressBox"
              type="checkbox"
              value="true"
              {...register("forWordpress")}
            />
            <label htmlFor="wordpressBox">
              Is this for the{" "}
              <Link to="https://wordpress.org/plugins/videomail-for-ninja-forms/">
                Ninja Form Videomail WordPress Plugin
              </Link>
              <span>?</span>
            </label>
          </div>
          <textarea
            placeholder="Anything else? (optional comments)"
            rows={2}
            {...register("comments")}
          ></textarea>
          <div id="sendWrapper">
            <input disabled={disableSubmitButton} type="submit" value="+ Submit" />
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default Whitelist;
