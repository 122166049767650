import { Link } from "react-router-dom";

import getExpirationPretty from "../../../common/util/getExpirationPretty";
import { whitelistPath } from "../../router";
import { getExpireSeconds } from "../../utils/options";

const FAQ = () => {
  const expirationPretty = getExpirationPretty(getExpireSeconds());

  return (
    <>
      <h2>Frequently Asked Questions</h2>
      <ul>
        <li>
          <a href="/faq/#theidea">What is the idea of videomail.io?</a>
        </li>
        <li>
          <a href="/faq/#opensource">Can I integrate Videomail into my website?</a>
        </li>
        <li>
          <a href="/faq/#storage">Where and how are the videos stored?</a>
        </li>
        <li>
          <a href="/faq/#security">What security measures are here?</a>
        </li>
        <li>
          <a href="/faq/#linksharingrisk">
            How can I prevent strangers seeing my videomails?
          </a>
        </li>
        <li>
          <a href="/faq/#iwantmore">
            I want more: faster uploads, attachments, an iPhone app, Gmail integration …
          </a>
        </li>
      </ul>
      <h3 className="question" id="theidea">
        What is the idea of videomail.io?
      </h3>
      <div className="answer">
        <p>
          Email is fine but not expressive. Video conferencing is not asynchronous like
          email. There is no ideal communication solution for Sign Language users like
          Deaf people. In a rapidly developing internet world, people have unlearnt how to
          express themselves visually. <em>We live in a talk overrated world.</em>
        </p>
        <p>
          videomail.io comes to the rescue: it enables you to record and send a short
          video from your webcam as if it were an email. You can express yourself in more
          than just words and send a lovely smile of yours to your friends and family.
          From anywhere. All you need is just to load up www.videomail.io on your desktop
          machine or mobile. No installation is required. Very simple.
        </p>
        <p>
          It&rsquo;s stable now. Free for everyone. In weekly iterations we add new
          features and improve the user experience constantly. Hence your feedback is very
          important to us.
        </p>
        <p className="note">
          On a side note, I, the programmer of this site, am proudly Deaf and thus
          identify myself with this invention. Always wanted to be able to send out emails
          in Sign Language directly …
        </p>
      </div>
      <h3 className="question" id="opensource">
        Can I integrate Videomail into my website?
      </h3>
      <div className="answer">
        <p>
          Yes! You can integrate Videomail into your own website. For example in a contact
          form, this to enable submissions in Sign Language.
        </p>
        <p>
          The <a href="//github.com/binarykitchen/videomail-client">Videomail Client</a>{" "}
          code is open sourced on GitHub, written in pure JavaScript and available as an{" "}
          <a href="//www.npmjs.com/package/videomail-client">npm package</a>. It is very{" "}
          <a href="//github.com/binarykitchen/videomail-client/blob/master/src/options.js">
            customizable
          </a>
          .
        </p>
        <p>
          Wicked, there is also a WordPress plugin, see{" "}
          <a href="//wordpress.org/plugins/videomail-for-ninja-forms/">
            Videomail for Ninja Forms
          </a>
          . Just install it on your WordPress site, drag and drop it over any{" "}
          <a href="//ninjaforms.com/">Ninja Form</a>. So simple.
        </p>
        <p>
          Since we do not want to host any &ldquo;bad&rdquo; videos, any live site using
          the above tools/plugins must submit a{" "}
          <Link to={whitelistPath}>Whitelist Request</Link>. For local development they
          work right away without the need to whitelist.
        </p>
      </div>
      <h3 className="question" id="storage">
        Where and how are the videos stored?
      </h3>
      <div className="answer">
        <p>
          All recorded videos are stored as webM and MP4 files. On a secure Auckland CBD
          based Linux server for <b>{expirationPretty}</b> (external sites using the API
          have a different life-span). After expiration they are deleted permanently to
          free up disk space.
        </p>
        <p>
          If you want to save the video before it expires, just right-click on the video
          and save it locally.
        </p>
      </div>
      <h3 className="question" id="security">
        What security measures are here?
      </h3>
      <div className="answer">
        <p>
          This server is hidden behind a firewall and is armed with extreme security
          settings, on top of being maintained every day. Any hack attempts into the
          server are automatically blocked.
        </p>
        <p>
          videomail.io itself is on SSL; all traffic, all media, anything is high-grade
          256 bit keys encrypted before being transmitted over HTTP2 or secure WebSockets.
        </p>
      </div>
      <h3 className="question" id="linksharingrisk">
        How can I prevent strangers seeing my videomails?
      </h3>
      <div className="answer">
        <p>
          When you send a message, videomail.io sends the recipient an email with a link
          to your video. The link includes an unique 32 digit ID that no one can guess,
          and the video can&rsquo;t be found by search engines. So no one will stumble
          across it. However the sender or the recipient can share the link to anyone at
          will.
        </p>
        <p>
          In other words, strangers won&rsquo;t be able to watch any videomails without
          this unique URL. As long as the sender and the recipient does not share the URL
          you&rsquo;re safe.
        </p>
        <p>
          Just be wary whenever you put the secret URL on social media or email it to your
          mother. Once it is in the wild, there is always the risk that someone else might
          download the video from there.
        </p>
        <p>
          People can&rsquo;t guess the URL because there are 340
          trillion-trillion-trillion possible URLs for 32 hexadecimal digits, and they
          can&rsquo;t find it through Google because search engines are blocked. Clever
          bad guys can&rsquo;t find them using sneaky code because it takes ages to crack
          it.
        </p>
      </div>
      <h3 className="question" id="iwantmore">
        I want more: faster uploads, attachments, an iPhone app, Gmail integration …
      </h3>
      <div className="answer">
        <p>
          We hear you. We have a huge backlog here with tons of ideas. Nothing is
          impossible for us and we&rsquo;re very motivated.
        </p>
        <p>
          We will implement those features first everyone is asking most. Any little
          donation would speeden up the development. Or you can even make a generous
          donation and ask us what to do. Really!
        </p>
        <p>Stay tuned for more crazy stuff to come!</p>
      </div>
    </>
  );
};

export default FAQ;
