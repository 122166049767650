import { createBrowserRouter } from "react-router-dom";
import VideomailClient from "videomail-client";

import type { Videomail, VideomailError } from "../common/types/Videomail";
import type VideomailClientType from "./pages/recorder/types/VideomailClient";

import ErrorBoundary from "./components/ErrorBoundary";
import Layout from "./components/Layout";
import Changelog from "./pages/changelog";
import FAQ from "./pages/faq";
import NotFound from "./pages/notfound";
import Correct from "./pages/recorder/mode/correct";
import Create from "./pages/recorder/mode/create";
import Reply from "./pages/recorder/mode/reply";
import Report from "./pages/report";
import View from "./pages/view";
import Whitelist from "./pages/whitelist";
import WhitelistThanks from "./pages/whitelist/thanks";
import getVideomailClientOptions from "./utils/getVideomailClientOptions";
export const homePath = "/";
export const viewRouteId = "viewRoute";
export const whitelistPath = "/whitelist/";
export const whitelistThanksPath = "/whitelist/thanks/";

const options = getVideomailClientOptions();

// Converted callback style to promise
function loadVideomail(alias: string | undefined) {
  if (!alias) {
    throw new Error("An alias for loading a videomail is missing");
  }

  const videomailClient = new VideomailClient(options) as VideomailClientType;

  return new Promise((resolve, reject) => {
    videomailClient.get(alias, (err?: VideomailError, videomail?: Videomail) => {
      videomailClient.unload();

      if (err) {
        reject(err);
      } else {
        resolve(videomail);
      }
    });
  });
}

const router = createBrowserRouter([
  {
    children: [
      {
        element: <Create />,
        index: true,
        path: homePath,
      },
      {
        element: <Correct />,
        loader: async ({ params }) => {
          const { alias } = params;
          const videomail = await loadVideomail(alias);

          return videomail;
        },
        path: "/videomail/:alias/correct/",
      },
      {
        element: <Reply />,
        loader: async ({ params }) => {
          const { alias } = params;
          const videomail = await loadVideomail(alias);

          return videomail;
        },
        path: "/videomail/:alias/reply/",
      },
      {
        element: <Reply replyAll={true} />,
        loader: async ({ params }) => {
          const { alias } = params;
          const videomail = await loadVideomail(alias);

          return videomail;
        },
        path: "/videomail/:alias/replyAll/",
      },
      {
        element: <Report />,
        loader: async ({ params }) => {
          const { alias } = params;
          const videomail = await loadVideomail(alias);

          return videomail;
        },
        path: "/videomail/:alias/report/",
      },
      {
        element: <View />,
        id: viewRouteId,
        loader: async ({ params }) => {
          const { alias } = params;
          const videomail = await loadVideomail(alias);

          return videomail;
        },
        path: "/videomail/:alias/",
      },
      {
        element: <FAQ />,
        path: "/faq/",
      },
      {
        element: <Changelog />,
        path: "/versions/",
      },
      {
        element: <Whitelist />,
        path: whitelistPath,
      },
      {
        element: <WhitelistThanks />,
        path: whitelistThanksPath,
      },
      {
        element: <NotFound />,
        path: "*",
      },
    ],
    element: <Layout />,
    errorElement: <ErrorBoundary />,
  },
]);

export default router;
